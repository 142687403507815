@import "./settings/colors.scss";

.settingsLayout{
  background: $white-color;
  padding: 30px;
  width: 60%;
  margin: auto;
  border-radius: 20px;
}

body[theme="dark"] .settingsLayout{
  box-shadow: 1px 2px 10px rgba(255, 255, 255, 0.4);
}

.inputArea{
  width: 250px;
  margin-top: -10px;
  margin-bottom: 30px;
}

.inputText{
  width: 100%;
}

.exitBtn{
  display: block;
  margin:  30px auto auto auto;
  width: 200px;
}

.themeBtn{
  font-family: "bold", sans-serif;
  border: 0px;
  border-radius: 8px;
  padding: 8px 35px 8px 35px;
  margin-top: -10px;
  margin-bottom: 30px;
  background: $notification-black-background;
  color: $white-color;
}

.themeBtn[theme='dark']{
  background: $home-background-color;
  color: $notification-black-background;
}

.themeIcon{
  margin: -1px 10px 0 0;
}

.downText{
  border-bottom: 2px solid $light-blue-color;
  line-height: 20px;
  vertical-align: middle;
  overflow: hidden;
  width: 250px;
  font-size: 16px;
  margin-bottom: 0px;
  color: $gray-menu-date-color;
}

.editButton{
  font-family: "bold", sans-serif;
  border: none;
  border-radius: 15px;
  padding: 5px 25px 5px 25px;
  margin-left: 30px;
  margin-bottom: 30px;
  background-color: $light-blue-color;
  color: $white-color;
}

.editButton[edit="true"]{
  background-color: $light-green-color;
}

.inputArea, .downText, .editButton{
  display: inline-block;
}

@media screen and (max-width: 992px){
  .settingsLayout{
    width: 95%;
  }
}

@media screen and (max-width: 468px){
  .editButton{
    display: block;
    margin: auto auto 20px auto;
  }

  .inputArea{
    width: 100%;
    margin-bottom: 10px;
  }

  .inputText{
    width: 100%;
  }
}