$input-area-color: #c9c9c9;
$background-color: #F9F9F9;
$white-color: white;
$light-gray-color: #EFEEEB;
$gray-menu-date-color: #A3A3A3;
$purple-color: #6564DB;
$light-red-color: #ff4545;
$red-color: #db6464;
$darken-red-color: #bb3a3a;
$home-background-color: #e3f8f9;
$green-button-background-color: #37DF34CC;
$assistant-button-color: #FAFF00;
$assistant-button-background: #FFFF74;
$purchases-button-date-color: #A4B4CB;
$purchases-active-button-color: #3D7AE5;
$orange-color: #ffbe59;
$notification-green-background: #7FBB7C;
$notification-black-background: #1E1E1E;
$radio_border: #EFEEEB;
$radio_active: #6564DB;
$radio_background: #f9f9f9;
$light-blue-color: #72a6ff;
$light-green-color: #71ffb3;
$assistant-message-background: rgba(255, 199, 0, 0.1);
$assistant-message-color: rgba(96, 40, 0, 0.8);
$user-message-background: rgba(55, 223, 52, 0.1);
$user-message-color: rgba(80, 145, 79, 0.8);
$assistant-bold-message-color: #F5640D;
$user-bold-message-color: rgba(37, 86, 36, 0.8);
$zero-opacity: rgba(0, 0, 0, 0);