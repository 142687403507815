@import "./settings/colors.scss";

.button{
  margin: 20px auto 20px auto;
  width: 350px;
  padding: 5px;
  text-decoration: none;
  text-align: center;
}

.big_logo {
  margin-bottom: 110px;
}

body[theme="dark"] .big_logo{
  color: $white-color;
}

@media screen and (max-width: 400px){
  .button{
    width: 300px;
  }
}