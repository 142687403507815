@import "../styles/settings/colors";

.categories{
  display: inline-block;
  width: 70px;
  padding: 0;
}

.categoriesRow{
  margin-left: 0;
}

.starsRow{
  display: inline-block;
  width: 20px;
  margin: auto 3px auto auto;
  line-height: 30px;
  vertical-align: middle;
}

p.starsRow{
  margin-left: 7px;
}

.shopImg{
  width: 100%;
}

.shop{
  display: inline-block;
  width: 250px;
}

.shopStars{
  text-align: center;
}

.categoryLayout{
  background: $white-color;
  padding: 15px;
  border-radius: 20px;
  margin-bottom: 30px;
}