@import "../settings/colors";

.img_icon{
  height: 30px;
}

body[theme="dark"] .purchaseAmount, body[theme="dark"] .purchaseTitle{
  color: $white-color;
}

body[theme="blue"] .purchaseAmount, body[theme="blue"] .purchaseTitle{
  color: $notification-black-background;
}

.purchaseOverflow{
  overflow: hidden;
}

.purchaseTitle{
  font-size: 12px;
  line-height: 20px;
  margin: auto auto auto 5px;
}

.purchaseDate{
  font-size: 9px;
  line-height: 10px;
  color: $gray-menu-date-color;
  margin: auto auto auto 5px;
}

.purchaseType{
  border: 0px;
  padding: 0 10px 0 10px;
  border-radius: 6px;
  font-size: 12px;
  margin-top: 7px;
  line-height: 20px;
  vertical-align: middle;
}

.purchaseArrow{
  height: 23px;
  display: block;
  margin: 5px 0 auto auto;
}

.purchaseAmount{
  text-align: right;
  font-size: 11px;
  line-height: 35px;
}

.purchaseDetails{
  background: $green-button-background-color;
  color: $white-color;
  border: none;
  border-radius: 8px;
  padding: 8px 12px 8px 12px;
  font-size: 12px;
}

hr{
  color: $purple-color;
  height: 1px;
  background: $purple-color;
  border: none;
  margin-top: -10px;
  margin-bottom: 10px;
}

.processingData{
  color: $orange-color;
}


@media screen and (max-width: 1400px){
  .purchaseTitle{
    line-height: 13px;
  }
}