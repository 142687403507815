@import "./settings/colors";

.button{
  margin-top: 30px;
  width: 100px;
}

body[theme="dark"] .button{
  box-shadow: 1px 2px 10px rgba(219, 100, 100, 0.4);
}

.button[validation="invalid"]{
  color: $red-color;
  border: 3px solid $red-color;
}

p{
  font-size: 22px;
}

body[theme="dark"] .headerText{
  color: $white-color;
}

.validatorAlert{
  display: none;
  font-size: 16px;
  margin-top: 10px;
  color: $red-color;
}

.validatorAlert[show="true"]{
  display: block;
}

.highlighted_text{
  color: $green-button-background-color;
}

.radioButton{
  display: block;
}

label{
  font-size: 20px;
  line-height: 15px;
}

body[theme="dark"] label{
  color: $white-color;
}

.chooseButton{
  margin-top: 30px;
}

input[type="radio"] {
  margin: 20px 0 0 20px;
  transform:scale(2.3);
  position: relative;
  top: -7px;
  left: 21px;
  z-index: 999;
  opacity: 0;
  + label {
    &:before {
      content: '';
      background: $radio_background;
      border-radius: 100%;
      //border: 3px solid $radio_border;
      border-color: $radio_border;
      box-shadow: 0 0 5px darken($radio_border, 17%);
      display: inline-block;
      width: 1em;
      height: 1em;
      position: relative;
      top: -0.2em;
      margin-right: 1em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease;
    }
  }
  &:checked {
    + label {
      &:before {
        background-color: $radio_active;
        border: 6px solid $radio_background;
        box-shadow: 0 0 5px darken($radio_border, 17%);
      }
    }
  }
  &:focus {
    + label {
      &:before {
        //border-color: $radio_active;
        outline: none;
      }
    }
  }
  &:disabled {
    + label {
      &:before {
        box-shadow: inset 0 0 0 4px $radio_border;
        border-color: $radio_border;
        background: $radio_background;
      }
    }
  }
  + label {
    &:empty {
      &:before {
        margin-right: 0;
      }
    }
  }
}