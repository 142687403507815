@import "./settings/colors";

.container_center {
  padding: 30px 5px 0 5px;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.big_logo {
  font-family: "bold", sans-serif;
  text-align: center;
}

input::placeholder{
  font-family: "light", sans-serif;
  color: $input-area-color;
}

input{
  font-family: "light", sans-serif;
  color: $input-area-color;
  background: none;
  border: 0px;
}

body[theme="white"] input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $gray-menu-date-color;
  -webkit-box-shadow: 0 0 0px 1000px $background-color inset;
  border: 1px solid $background-color;
}

body[theme="dark"] input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 2px solid $notification-black-background;
  -webkit-text-fill-color: $purple-color;
  -webkit-box-shadow: 0 0 0px 1000px $notification-black-background inset;
}

input:focus{
  outline: none;
}

.input_area{
  border: 2px solid $purple-color;
  border-radius: 30px;
  padding: 10px;
}

.input_area[validation="invalid"]{
  border: 2px solid $red-color;
}

.button{
  text-decoration: none;
  text-align: center;
  display: block;
  border-radius: 45px;
}

.navLink{
  text-decoration: none;
  text-align: center;
}

.button:hover{
  box-shadow: 0px $purple-color;
}

.btn_fill{
  border: 3px solid $purple-color;
  background: $purple-color;
  color: white;
}

body[theme="dark"] .btn_fill, .btn_empty{
  box-shadow: 1px 2px 10px rgba(101, 100, 219, 0.5);
}

.btn_fill:hover{
  background: $purple-color;
  color: white;
}

.btn_empty{
  color: $purple-color;
  border: 3px solid $purple-color;
}

.btn_empty:hover{
  color: $purple-color;
  border: 3px solid $purple-color;
}

.input_logo{
  font-size: 20px;
  color: $input-area-color;
}

.pageLayout{
  margin-top: 40px;
  padding: 10px 20px 10px 20px;
}

.exitBtn{
  font-family: "bold", sans-serif;
  background: $light-red-color;
  color: $white-color;
  border-radius: 10px;
  padding: 3px 25px;
}

.exitBtn:hover{
  color: $white-color;
}

.name_area{
  width: 100%;
  border: 1px solid $light-gray-color;
  border-radius: 12px;
  padding: 3px;
  margin-bottom: 16px;
}

.name_input{
  background: $white-color;
}
.underline_btn{
  font-size: 15px;
  color: $purchases-button-date-color;
  border: none;
  background: none;
  margin-bottom: 20px;
}

.underline_btn[status="active"]{
  transition: all 0.2s ease;
  color: $purchases-active-button-color;
  border-bottom: 2px solid $purchases-active-button-color;
  border-radius: 2px;
}
