@import "./settings/colors";

.input_area{
  margin: 20px auto 20px auto;
  width: 350px;
  padding: 5px;
}

.big_logo {
  margin-bottom: 50px;
}

.button{
  width: 200px;
  margin: 20px auto 20px auto;
  padding: 5px;
}

@media screen and (max-width: 400px){
  .input_area{
    width: 300px;
  }
}