@import "../settings/colors.scss";
@import '../elements.module';

.menu_btn{
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 0px;
  background: none;
  color: $gray-menu-date-color;
}

.menu_logo{
  height: 22px;
  vertical-align: middle;
  float: left;
  margin-left: 20px;
}

.menu_btn_active{
  color: $white-color;
  background: $purple-color;
  @extend .menu_btn;
  @extend .button;
}

.menu_btn_inactive{
  @extend .menu_btn;
  @extend .button;
}

body[theme="dark"] .menu_btn_active{
  box-shadow: 1px 2px 10px rgba(101, 100, 219, 0.5);
}

.menu_btn_active .menu_logo{
  color: $white-color;
}

.menu_btn:hover{
  color: $white-color;
  background: $purple-color;
}

.menu_btn:hover .menu_logo{
  color: $white-color;
}

.exitBtn{
  width: 150px;
  display: block;
  margin: auto;
}

@media screen and (max-width: 1200px){
  .menu_logo{
    height: 22px;
    margin-left: 10px;
  }
}