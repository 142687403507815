.chooseButton{
  display: block;
  border-radius: 10px;
  margin: 10px 0 0 5px;
  border: none;
  padding: 3px 15px 3px 15px;
}

@each $btn, $rgba1, $rgba2, $rgba3 in
                ("chooseButtonFood", 255, 0, 0),
                ("chooseButtonElectronics", 1, 184, 227),
                ("chooseButtonAutoproducts", 0, 0, 0),
                ("chooseButtonMedicine", 55, 223, 52),
                ("chooseButtonGasstations", 101, 100, 219),
                ("chooseButtonClothes", 245, 100, 13),
                ("chooseButtonPets", 245, 13, 236){
  .#{$btn}{
    color: rgba($rgba1, $rgba2, $rgba3, 0.8);
    background: rgba($rgba1, $rgba2, $rgba3, 0.2);
    //border: 1px solid rgba($rgba1, $rgba2, $rgba3, 0.3);
  }
  .#{$btn}[active="true"]{
    box-shadow: 0px 0px 5px rgba($rgba1, $rgba2, $rgba3, 0.8);
  }
}

body[theme="dark"]{
  @each $btn, $rgba1, $rgba2, $rgba3 in
                  ("chooseButtonFood", 255, 0, 0),
                  ("chooseButtonElectronics", 1, 184, 227),
                  ("chooseButtonAutoproducts", 255, 255, 255),
                  ("chooseButtonMedicine", 55, 223, 52),
                  ("chooseButtonGasstations", 101, 100, 219),
                  ("chooseButtonClothes", 245, 100, 13),
                  ("chooseButtonPets", 245, 13, 236){
    .#{$btn}{
      color: rgba($rgba1, $rgba2, $rgba3, 0.8);
      background: rgba($rgba1, $rgba2, $rgba3, 0.4);
      //border: 1px solid rgba($rgba1, $rgba2, $rgba3, 0.3);
    }
    .#{$btn}[active="true"]{
      box-shadow: 0px 0px 8px rgba($rgba1, $rgba2, $rgba3, 0.8);
    }
  }
}