@import "./settings/colors";

@font-face {
  font-family: 'bold';
  src: url(../../public/fonts/TTFirsNeue-Bold.woff2);
}

@font-face {
  font-family: 'medium';
  src: url(../../public/fonts/TTFirsNeue-Medium.woff2);
}

@font-face {
  font-family: 'light';
  src: url(../../public/fonts/TTFirsNeue-Light.woff2);
}

body{
  font-family: "medium", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: $zero-opacity;
}

::-webkit-scrollbar-thumb {
  background: #6564DB;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #5c5bce;
}