@import "../styles/settings/colors";

.startMessage{
  width: 175px;
  margin: 100px 0 30px auto;
  text-align: right;
  background: $white-color;
  border-radius: 10px;
  padding: 10px 30px 10px 30px;
}

.chatLayout{
  position: relative;
  background: $background-color;
  border-radius: 30px;
  height: 580px;
}

.chatWindow{
  height: 460px;
  padding: 25px;
  overflow-y: auto;
  overflow-x: hidden;
}

.messagesArea{
  margin-top: 35px;
  margin-bottom: -30px;
}

.newMessageArea{
  margin-top: 20px;
}

.assistantMessage, .userMessage{
  padding: 20px 60px 20px 20px;
  font-size: 16px;
}

.assistantMessage{
  color: $assistant-message-color;
  border-radius: 1px 20px 20px 20px;
  background-color: $assistant-message-background;
}

.userMessage{
  color: $user-message-color;
  border-radius: 20px 1px 20px 20px;
  background-color: $user-message-background;
}

.assistantMessage b{
  color: $assistant-bold-message-color;
}

.userMessage b{
  color: $user-bold-message-color;
}

.assistantIcon{
  margin-top: -35px;
}

.userIcon{
  margin-top: -45px;
}

.assistantIcon img{
  width: 45px;
}

.userIcon img{
  margin-left: -15px;
  width: 55px;
}

::-webkit-scrollbar-track {
  margin-top: 25px;
}

.quickMessageButton{
  background: $assistant-message-background;
  border: 0;
  border-radius: 10px;
  padding: 5px 25px 5px 25px;
  color: $assistant-message-color;
  margin-left: 20px;
}

.writeNewMessageArea{
  margin: 10px;
  background: $white-color;
  padding: 10px 10px 10px 50px;
  border-radius: 30px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
}

.newMessageInput{
  width: 93%;
}

.assistant{
  margin-left: -40px;
}

.emptyButton{
  margin-top: 64px;
}