@import "./settings/colors";

.images{
  padding: 10px;
}

.blocks{
  width: 80%;
  margin: auto;
}

.images img{
  width: 100%;
}

.images .smallerImg{
  height: 93%;
  margin-left: 10px;
}

.avatar_background{
  background: $white-color;
  border-radius: 20px;
  text-align: center;
  padding-bottom: 10px;
  height: 100%;
}

.changeButton{
  width: 50px;
  position: relative;
  top: -110px;
  left: 60px;
  margin-bottom: -100px;
}

.nameText{
  overflow: hidden;
  font-size: 17px;
}

.cardText{
  font-size: 14px;
  margin-bottom: -10px;
}

.assistantMessage{
  margin: 10px auto auto auto;
  background: $purple-color;
  border-radius: 20px;
  padding: 20px;
  color: $white-color;
}

.messageText{
  font-size: 16px;
  margin-right: 120px;
}

span, .assistantButton{
  color: $assistant-button-color;
}

.assistantMessage, .assistantButton{
  font-family: "light", sans-serif;
}

body[theme="dark"] .purchasesHeader{
  color: $white-color;
}

.assistantButton{
  text-decoration: none;
  border: 2px solid $assistant-button-background;
  border-radius: 20px;
  background: none;
  padding: 5px 25px;
  width: 100px;
  margin-top: 20px;
}

.assistantButton:hover{
  color: $assistant-button-background;
}

.assistant{
  position: relative;
  top: -258px;
  left: 228px;
}

.card{
  width: 100%;
}

.date{
  font-size: 14px;
  color: $purchases-button-date-color;
  margin-bottom: 5px;
}

.purchasesLayout[status="active"]{
  display: block;
}

.purchasesLayout[status="inactive"]{
  display: none;
}

@media screen and (max-width: 1400px){
  .assistant{
    left: 153px;
  }
  .messageText{
    margin-right: 100px;
  }
}

@media screen and (max-width: 1200px) {
  .messageText {
    margin-right: 0;
  }

  .cardText {
    margin-top: 60px;
  }

  .blocks {
    width: 100%;
  }

  .assistant {
    width: 150px;
    top: -80px;
    left: 180px;
  }

  .assistantLayout {
    padding: 0;
  }

  .avatar_background{
    width: 85%;
    margin: 0 auto auto auto;
  }
}

@media screen and (max-width: 992px){
  .assistant{
    left: 553px;
  }
  .card{
    display: block;
    margin: auto;
    width: 50%;
  }
  .onlyCard{
    margin-top: -60px;
  }
}

@media screen and (max-width: 768px){
  .assistant{
    left: 383px;
  }
}

@media screen and (max-width: 576px){
  .assistant{
    left: 60%;
  }
}