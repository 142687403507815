@import "../settings/colors";

.inlineElement{
  display: inline-block;
}

img.inlineElement{
  width: 80px;
}

.categoriesList{
  position: relative;
  left: 50%;
  transform: translate(-25%);
}

.category{
  margin-bottom: 10px;
}

.toggleBtn{
  border-radius: 50%;
  margin-left: 10px;
  font-size: 25px;
  transition: 0.3s;
}

body[theme="dark"] .toggleBtn{
  color: $white-color;
}

.toggleBtn[choosed="true"]{
  transition: 0.3s;
  transform: rotate(45deg);
}

@media screen and (max-width: 576px){
  .categoriesList{
    left: 0;
    transform: translate(0);
  }
}