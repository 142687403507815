@import "../settings/colors.scss";

.layout{
    position: relative;
    z-index: 999;
    margin-top: 40px;
    background: $white-color;
    padding: 10px;
    border-radius: 24px;
    height: 80px;
}

body[theme="dark"] .layout{
    box-shadow: 1px 2px 10px rgba(255, 255, 255, 0.4);
}

.layout, .notifications_layout{
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
}

.logo{
    font-family: "bold", sans-serif;
    text-align: center;
    line-height: 60px;
    vertical-align: middle;
}

.search_area{
    width: 100%;
    border: 1px solid $light-gray-color;
    border-radius: 12px;
    padding: 7px;
    margin-top: 9px;
}

.search_input{
    margin-left: 10px;
    width: 80%;
    background: $white-color;
}

.date{
    font-family: "light", sans-serif;
    font-size: 14px;
    text-align: right;
    color: $gray-menu-date-color;
}

.date, .user{
    overflow: hidden;
    line-height: 60px;
    vertical-align: middle;
}

.user{
    font-size: 16px;
    text-align: right;
}

.userArrow{
    margin-left: -30px;
    font-size: 24px;
    line-height: 58px;
    vertical-align: middle;
}

.notifications{
    display: block;
    margin: 13px 0 auto auto;
}

.menu_info{
    text-align: center;
}

.avatar{
    margin-left: -50px;
}

.arrowMenu{
    font-size: 24px;
    text-align: center;
    height: 60px;
    vertical-align: middle;
}

.notifications_layout{
    font-family: "light", sans-serif;
    z-index: 999;
    position: absolute;
    top: 100px;
    width: 400px;
    background: $white-color;
    padding: 20px 40px 20px 40px;
    left: 51%;
    border-radius: 20px;
}

.notifications_layout[status="inactive"]{
    display: none;
}

h5{
    font-family: "bold", sans-serif;
}

.notificationsTitle{
    line-height: 100px;
}

b{
    font-family: "medium", sans-serif;
}

.notificationImg{
    position: relative;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
}

.notificationTextBlack{
    line-height: 20px;
    margin-top: calc(100px - 20px - 48px);
}

.notificationTextGreen{
    width: 100px;
    line-height: 20px;
    margin-top: calc(100px - 20px - 48px - 12px);
}

.blackout[status="active"]{
    z-index: 900;
    margin-top: -100px;
    position: fixed;
    width: 100%;
    height: 200vh;
    background: rgba(0,0,0, 0.8);
    opacity: 0.2;
    z-index: 900;
}

.notifications_blocks{
    vertical-align: middle;
    color: $white-color;
    border-radius: 20px;
    height: 100px;
}

.notifications_blocks p{
    font-size: 16px;
}

.notifications_blocks_green{
    background: $notification-green-background;
}

.notifications_blocks_black{
    background: $notification-black-background;
}

.notifications_blocks_black p{
    text-align: right;
}

.notificationDate{
    font-size: 12px;
    text-align: right;
    margin-top: 5px;
}

h2{
     /*border-right: 1px solid #d2d2d2;*/
 }

@media screen and (max-width: 1300px){
    .notifications_layout{
        left: 47%;
    }
}


@media screen and (max-width: 1200px){
    .notifications_layout{
        left: 35%;
    }
}

@media screen and (max-width: 992px){
    .notifications_layout{
        left: 30%;
    }

    .arrowMenu{
        margin-left: -30px;
    }
}

@media screen and (max-width: 768px){
    .avatar{
        margin-left: -20px;
    }

    .layout{
        margin-bottom: -30px;
    }

    .notifications_layout{
        left: 50%;
        transform: translate(-50%);
        width: 100%;
        margin: auto;
    }

    .notifications{
        margin-left: -10px;
    }

    .arrowMenu{
        margin-left: 10px;
    }
}

@media screen and (max-width: 576px){
    .layout{
        width: 95%;
    }

    .avatar{
        margin-left: 10px;
    }

    .notifications{
        margin-left: 5px;
    }

    .search_area{
        margin-left: 20px;
        width: 90%;
    }

    .search_input{
        width: 70%;
    }

    .arrowMenu{
        margin-left: 0;
    }
}

@media screen and (max-width: 430px){
    .search_area{
        width: 100%;
    }

    .search_input{
        width: 80%;
    }

    .notifications{
        margin-left: 5px;
    }

    .notificationTextBlack{
        width: 140px;
    }
}

@media screen and (max-width: 360px){
    .search_input{
        width: 75%;
    }
}