@import "./colors.scss";

body[theme="blue"]{
    background: $home-background-color;
}

body[theme="white"]{
    background: $background-color;
}

body[theme='dark']{
    background: #1E1E1E;
}